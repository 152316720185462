import { BrowserModule } from "@angular/platform-browser";
import { NgModule, LOCALE_ID } from "@angular/core";
import { registerLocaleData, DatePipe } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { Amplify, I18n } from "aws-amplify";
import awsconfig from "./../aws-exports";
import { AngularMaterialModule } from "./angular-material.module";
import { AuthComponent } from "./auth/auth.component";
import { AmplifyService, AmplifyAngularModule } from "aws-amplify-angular";
import { I18nAmplify } from './i18n/i18n-amplify';
import {  NotificationsModule } from './shared/notifications/notifications.module';
import { HttpRequestInterceptor } from './sesame/service/httpRequest.interceptor';
import { FicheMissionModule } from "./sesame/fiche-mission/fiche-mission.module";
import { FlexLayoutModule } from '@angular/flex-layout';

registerLocaleData(localeFr, 'fr');

Amplify.configure(awsconfig);
I18n.setLanguage("fr");
I18n.putVocabularies(new I18nAmplify().getDict());

@NgModule({
    declarations: [AppComponent, AuthComponent],
    imports: [
        AppRoutingModule,
        BrowserAnimationsModule,
        BrowserModule,
        HttpClientModule,
        AngularMaterialModule,
        AmplifyAngularModule,
        NotificationsModule,
        FlexLayoutModule 
    ],
    providers: [AmplifyService, DatePipe,  { provide: LOCALE_ID, useValue: 'fr-FR' },

    {
        provide : HTTP_INTERCEPTORS,
        useClass: HttpRequestInterceptor,
        multi   : true,
      }],

    bootstrap: [AppComponent],
})
export class AppModule {}
