import { NotificationsComponent } from './notifications.component';
import { ToastrNotificationsService } from './services/toastr-notifications.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({    
    declarations: [    
        NotificationsComponent    
    ],  
    imports:[  
        CommonModule  
    ],  
    exports: [    
        NotificationsComponent 
    ],providers:[    
        ToastrNotificationsService    
    ]    
})    
export class NotificationsModule    
{    
}    