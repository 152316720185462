import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API } from 'aws-amplify';
import { UserApp } from 'src/app/model/userApp.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  
  userRole;
  userId;
  username;
  isLoggedIn:boolean = true;
   endpoint;
  private apiName = "isoprodAPI";
    userToken: any;

  constructor(private http: HttpClient) {
    
   }

  
  async userExists() {
      const body = {
        username: this.username
      }
      let users : UserApp[] = new Array();
      await this.http.post<any>(this.endpoint + '/user/verify',body).toPromise().then(async response =>  {
        users = await response.results as UserApp[];
      });
      if(users.length > 0){
        return true;
      } else {
      return false;
      }
 
}

  createUser(userApp: UserApp) {
    const req = userApp
    return this.http.post<any>(this.endpoint + '/users',req).toPromise();
  }

  getRoleId(roleName: string) {
    return this.http.get<any>(this.endpoint +'/role/id/' + roleName).toPromise();
  }

  getUserId(username): Promise<any> {
    return new Promise(async (resolve,reject) => {
      let url = '/getUserId/';
      url+=username;
      var users : UserApp[] = new Array();
      this.http.get<any>(this.endpoint + url).toPromise().then(response =>  {
        users = response.results as UserApp[];
        if(users.length > 0){
          resolve(users);
        } else {
          reject(false);
        }
      });
    });
  }
}
