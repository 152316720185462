import { Component, OnInit } from '@angular/core';
import { ToastrNotificationsService } from './services/toastr-notifications.service';
import { Notification } from './model/notification.model';
import { NotificationType } from './model/notificationType.enum';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  notifications: Notification[] = new Array();
  private timeout: number = 6000;

  constructor(public _notificationService: ToastrNotificationsService) { }

  ngOnInit() {
      this._notificationService.getAlert().subscribe((alert: Notification) => {
        this.notifications = [];
        if(!alert) {
          this.notifications = new Array();
          return;
        }
        this.notifications.push(alert);
        setTimeout(() => {
          this.notifications = this.notifications.filter(x => x !== alert);
        }, this.timeout)
      });
  }

  removeNotification(notification: Notification){
    this.notifications = this.notifications.filter(x => x !== notification);
  }

  cssClass(notification: Notification) {
    if (!notification) {
      return;
    }
    switch (notification.type) {
        case NotificationType.Success:
            return 'toast-success';
        case NotificationType.Error:
            return 'toast-error';
        case NotificationType.Info:
            return 'toast-info';
        case NotificationType.Warning:
            return 'toast-warning';
    }
  }



}
