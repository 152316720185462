import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { API } from 'aws-amplify';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable()


export class HttpRequestInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService) {
    }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  
    if(this.authService.isLoggedIn){
        req = req.clone({
            setHeaders: {
              'Content-Type' : 'application/json; charset=utf-8',
              'Accept'       : 'application/json',
              'Authorization': `${this.authService.userToken}`,
              },
              
            })
    }
    
      return next.handle(req);
    }
    
}