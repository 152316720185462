import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from "@angular/core";
import { AuthService } from './sesame/service/auth.service';

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
    title = "sesame-ui";

    constructor(private http: HttpClient,
                private authService: AuthService ){

    }
    
    ngOnInit() {
        //Reading endpoint from config file
        this.http.get("assets/config.json").toPromise().then(data => {

            this.authService.endpoint = data['endpoint'];
          })
    }        
}
