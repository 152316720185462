import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { Router, NavigationStart } from '@angular/router';
import { NotificationType } from '../model/notificationType.enum';
import { Notification } from '../model/notification.model';

@Injectable({
  providedIn: 'root'
})
@Injectable()
export class ToastrNotificationsService {
  public subject = new Subject<Notification>();
  public keepAfterRouteChange = true;


  constructor(public router: Router) {

      router.events.subscribe(event => {
        if(event instanceof NavigationStart){
            if(this.keepAfterRouteChange) {
              this.keepAfterRouteChange = false;
            } else {
                this.clear();
            }
        }
      });
   }

   getAlert(): Observable<any> {
     return this.subject.asObservable();
   }

   success(message:string, keepAfterRouteChange = false){
     this.showNotification(NotificationType.Success, message, keepAfterRouteChange);
   }

   error(message:string, keepAfterRouteChange = false){
    this.showNotification(NotificationType.Error, message, keepAfterRouteChange);
  }

  info(message:string, keepAfterRouteChange = false){
    this.showNotification(NotificationType.Error, message, keepAfterRouteChange);
  }

  warn(message:string, keepAfterRouteChange = false){
    this.showNotification(NotificationType.Error, message, keepAfterRouteChange);
  }

  showNotification(type: NotificationType, message: string, keepAfterRouteChange){
    this.keepAfterRouteChange = keepAfterRouteChange;
    this.subject.next(<Notification>{type: type, message: message});
  }

  clear() {  
    this.subject.next();  
}

   
}
