/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cloud_logic_custom": [
        {
            "name": "v1",
            "endpoint": "https://vocrifn92j.execute-api.eu-west-1.amazonaws.com/prod",
            "region": "eu-west-1"
        }
    ],
    "aws_cognito_identity_pool_id": "eu-west-1:4e3b3c5c-dab9-4250-a819-8e63ca3d24ac",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_V5Q8RJHDT",
    "aws_user_pools_web_client_id": "4a7gi3hnbolu9e74e7tbhmicb7",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "oisinalinrod-20201222121016-hostingbucket-prod",
    "aws_content_delivery_bucket_region": "eu-west-1",
    "aws_content_delivery_url": "https://d100ctm464605u.cloudfront.net"
};


export default awsmobile;
