import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthComponent } from './auth/auth.component';

const routes: Routes = [
    {
        path: "sesame",
        loadChildren: () =>
            import("./sesame/sesame.module").then((m) => m.SesameModule),
    },
    {
        path: "login",
        component: AuthComponent
      },
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
      }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
