export class I18nAmplify {
  dict: any = {
  'fr': {
    'Hello, {{username}}' : "Bonjour, {{username}}",
      'Sign In': "Se connecter",
      'Sign Up': "S'inscrire",
      'Username': "Nom d'utilisateur",
      'Password *': "Mot de passe *",
      'Password' : "Mot de passe",
      'Enter code' : "Code de confirmation",
      'Forgot Password?': "Mot de passe oublié ?",
      'Sign in to your account': "Connectez-vous à votre compte",
      'Reset password': "Réinitialiser le mot de passe",
      'Enter your password': "Entrez votre mot de passe",
      'No account?': " ",
      'Create account': " ",
      'Sign Out' : "Se déconnecter",
      'Reset your password' :  "Réinitialisation du mot de passe",
      'You will receive a verification code' : "Vous allez recevoir un code de vérification",
      'Submit' : "Soumettre",
      'Back to Sign in' : "Revenir à la page de connexion",
      'Confirmation Code *': "Code de confirmation *",
      'New Password *' : 'Le nouveau mot de passe *',
      'Enter the code you received and set a new password': "Entrez le code reçu et mettez à jour votre mot de passe",
      'Resend Code' : "Renvoyer le code",
      'Verify': "Vérifier",
      //Errors 
      'Incorrect username or password.':"Nom d'utilisateur ou mot de passe incorrect.",
      'Username cannot be empty' : "Le nom d'utilisateur ne peut être vide.",
      'User does not exist.' : "Nom d'utilisateur ou mot de passe incorrect.",
      'Username/client id combination not found.' : "Le nom d'utilisateur n'existe pas.",
      'Cannot reset password for the user as there is no registered/verified email or phone_number' : "L' utilisateur n'a pas d'adresse mail/numéro de téléphone vérifié",
      'Attempt limit exceeded, please try after some time.': "Limite de tentatives dépassée, veuillez essayer après un certain temps.",
      '2 validation errors detected: Value at \'password\' failed to satisfy constraint: Member must have length greater than or equal to 6; Value at \'password\' failed to satisfy constraint: Member must satisfy regular expression pattern: ^[\\S]+.*[\\S]+$'  : 
                  "2 erreurs de validation détectées: la valeur du mot de passe n'a pas satisfait à la contrainte: le membre doit avoir une longueur supérieure ou égale à 6; La valeur du mot de passe n'a pas satisfait la contrainte: le membre doit satisfaire le modèle d'expression régulière: ^[\\S]+.*[\\S]+$",
      'Confirmation code cannot be empty' : "Le code de confirmation est obligatoire",
      'Invalid verification code provided, please try again.' : "Code de confirmation incorrect, veuillez ressayer ou renvoyer le code.",
      'User is disabled.': 'Le compte de cet \'utilisateur est désactivé.'
  },
};
  getDict(): any {
      return this.dict;
}
}
